<template>
  <div class="px-4 shadow-lg bg-white h-screen fixed">
    <div class="my-10 text-lg px-3">
      <img src="@/assets/logo_black.png" class="w-full" alt="">
    </div>
    <hr>
    <div v-if="token != null">
      <div class="py-2 cursor-pointer hover:bg-blue-100 rounded-lg hover:shadow" @click="onClick(l)" v-for="(l, i) in links" :key="i">
        {{ l.text }}
      </div>
    </div>
    
  </div>
</template>
<script lang="ts">
import {  defineComponent, onMounted, ref, SetupContext } from 'vue';
import { useRouter } from 'vue-router';
import useAuth from '@/types/Auth'

export default defineComponent({
  setup(props: any, context: SetupContext) {
    const router = useRouter();
    const {
      token,     
    } = useAuth()
    
    const links = [
      {
        name: 'ProposalsList',
        text: '一覧画面',
      },
      {
        name: 'ProposalsStatus',
        text: '案件ステータスマスタ',
      },
      {
        name: 'Purpose',
        text: '検討内容マスタ',
      },
      {
        name: 'OwnerRelation',
        text: 'オーナ様との関係マスタ',
      },
      {
        name: 'Benefit',
        text: '特典マスタ',
      },
      {
        name: 'OwnerType',
        text: 'オーナ様種類マスタ',
      },
      {
        name: 'Login',
        text: 'ログアウト'
      }
      
      
    ];
    const onClick = (l: any) => {
      context.emit('close')
      router.push({ name: l.name })

    }
    return {
      links,
      token,
      onClick,
    }
  }
})
</script>