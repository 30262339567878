import moment from 'moment'
import {  RouteRecordName } from 'vue-router'

const formatDate = (val: string, format: string) => {
  return moment(val).format(format);  
}

const routerNotCheckAuth: Array<RouteRecordName> =  [
  'RequestResetPassword',
  'RequestResetPasswordDone',
  'ResetPaswordConfirm',
  'ResetPasswordComplete'
]
export {
  formatDate,
  routerNotCheckAuth
}