import axios from 'axios';

const SERVER_NAME = process.env.VUE_APP_HOST; //'http://localhost:8000';
// const SERVER_NAME = 'https://pixcardio.com'
const API_BASE = `${SERVER_NAME}/api`;

const client = axios.create({
  baseURL: API_BASE
});

const isAuthUrl = (url: string | undefined) => {
  if (!url) return false;
  return url.includes('token');  
};

const getAccessToken = () => {
  const value = window.localStorage.getItem('token');
  if (value == null) return null;
  try {
    const tokenJson = JSON.parse(value);
    return tokenJson.access;
  } catch (err) {
    return null
  } 
};

client.interceptors.request.use(req => {
  // Important: request interceptors **must** return the request.
  if (isAuthUrl(req.url) || req.url === '/signup/' || req.url?.includes('payme')) return req;
  const accessToken = getAccessToken();
  if (accessToken == null) {
    
    // router.push({ name: 'Login' });
    // window.location.href = '/'
    return req;
  }
  req.headers.Authorization = `Bearer ${accessToken}`;
  return req;
});

client.interceptors.response.use(
  res => res,
  error => {
    if (error.response == null) return;
    const { status } = error.response;
    // alert(error.config.url)
    if (!isAuthUrl(error.config.url) && status === 401) {
      // alert('goto login')
      window.location.href = '/';
      // router.push({ name: 'Login' });
      // window.location.href = '/'
      throw error;
    }
    throw error;
  }
);

export { client, SERVER_NAME };