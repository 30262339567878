<template>
  <router-view/>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<script lang="ts">
import { defineComponent, onMounted, ref, SetupContext } from 'vue'
import {useRouter} from 'vue-router'
import useAuth from '@/types/Auth'
import { routerNotCheckAuth } from './utils/index'

export default defineComponent({
  setup(props: any, context: SetupContext) {
    const {
      token,
      getTokenFromLocalStorage,
      profile,
      getProfileFromLocalStorage
    } = useAuth();
    
    const router = useRouter()

    onMounted(() => {
      token.value = getTokenFromLocalStorage()
      profile.value = getProfileFromLocalStorage()
      if (token.value == null || profile.value == null) {
        const routerName = router.currentRoute.value.name
        if (routerName && routerNotCheckAuth.indexOf(routerName) == -1) {
          router.push({ name: 'Login' })
        }
      }
    })

    return {

    }
  }
})
</script>