<template>
  <div class="flex items-center justify-center">
    <div
      :style="`background-color: ${modelValue}`"
      class="w-4 h-4 rounded-full shadow-lg"  
    >
      
    </div>
    <div class="ml-3">
      {{ modelValue }}  
    </div>    
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, PropType, ref, SetupContext } from 'vue';

export default defineComponent({
  components: {
        
  },
  props: {
    modelValue: {
      type: String,
    }
  },
  setup(props, ctx: SetupContext) {
    return {
    }
  }
})
</script>