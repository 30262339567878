

import { ref } from 'vue'

import { IFlashMessage } from '@/types/Interfaces'

const message = ref<IFlashMessage | null>(null);

const setMessage = (m: IFlashMessage) => {
  message.value = m;
  window.setTimeout(() => {
    message.value = null
  }, 3000)
}
const removeMessage = () => {
  message.value = null
}
export default function useFlashMessage() {
  
  return {
    message,
    setMessage,
    removeMessage
  }
}