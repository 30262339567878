<template>
  <div  
    class="flex "
  >
    
    <sidebar class="w-64" />
    <div
      class="relative px-2 flex flex-col flex-grow overflow-y-auto min-h-screen ml-64 overflow-x-auto"
    >
      
      <div class=" border-b py-4 text-left px-6 flex">
        
        <slot name="title"></slot>
        
        <div v-if="profile" class="ml-auto">
          {{ profile.email }} 様
        </div>
      </div>
      
      <slot name="default">
      </slot>
      
    </div>
    <div
        v-if="message"
        class="fixed  w-full bottom-5"
      >
        <div
          style="min-width: 500px"
          :class="{ 'bg-black': message.type == 'success', 'bg-red-400': message.type == 'error' }"
          class="ml-64 rounded-lg shadow px-6 py-4 text-white inline-block ">
          <div class="flex items-center">
            <div class="">{{ message.message }}</div>
            <svg
              @click="dismissMessage"
              xmlns="http://www.w3.org/2000/svg" class="cursor-pointer w-4 ml-auto" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
          </div>
        </div>
    </div>
      
    <div class="loading" v-if="props.loading">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  </div>
</template>
<style lang="scss">
.loading {
  width: 100%;
  position: absolute;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  opacity: 0.3;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid rgba(16, 185, 129);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: rgba(16, 185, 129) transparent transparent transparent;
    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
<script lang="ts">
import { defineComponent,ref, onMounted, withDirectives, onBeforeUnmount, onUpdated } from 'vue';
import Navbar from '@/components/Navbar.vue';
import Sidebar from '@/components/Sidebar.vue';
import useAuth from '@/types/Auth'
import useFlashMessage from '@/types/FlashMessage';

export default defineComponent({
  props: {
    loading: Boolean
  },
  components: {
    // Navbar,
    Sidebar
  },
  setup(props) {
    const {
      profile
    } = useAuth();
    
    const {
      message,
      removeMessage,
    } = useFlashMessage();
    const dismissMessage = () => {
      removeMessage()
    }
    return {
      profile,
      message,
      dismissMessage,
      props
    }
  }
})
</script>