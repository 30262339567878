
import { defineComponent,ref, onMounted, withDirectives, onBeforeUnmount, onUpdated } from 'vue';
import Navbar from '@/components/Navbar.vue';
import Sidebar from '@/components/Sidebar.vue';
import useAuth from '@/types/Auth'
import useFlashMessage from '@/types/FlashMessage';

export default defineComponent({
  props: {
    loading: Boolean
  },
  components: {
    // Navbar,
    Sidebar
  },
  setup(props) {
    const {
      profile
    } = useAuth();
    
    const {
      message,
      removeMessage,
    } = useFlashMessage();
    const dismissMessage = () => {
      removeMessage()
    }
    return {
      profile,
      message,
      dismissMessage,
      props
    }
  }
})
