import { client } from '@/types/Axios';
import { ILogin, IToken, IUser, IResetPassword, IUpdatePassword } from '@/types/Interfaces';
import { computed, ref, ComputedRef, withDirectives } from 'vue';
import { cloneDeep, clone } from "lodash";

const TOKEN_STORAGE_KEY = 'token';

const token = ref<IToken | null>(null);
const profile = ref<IUser | null>(null);

const storeToken = (data: IToken) => {
  token.value = data;
  window.localStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify(data));
}
const getTokenFromLocalStorage = () => {
  const data = window.localStorage.getItem(TOKEN_STORAGE_KEY);
  if (data == null) return null;
  try {
    return JSON.parse(data);    
  } catch (err) {
    return null
  }
};

const createToken = async (postData: ILogin) => {
  const {data} = await client.post('/token/create/', postData);
  console.log(data)
  return data;
};

const fetchUsers = async () => {
  const {data} = await client.get('users/')
  console.log(data)
  return data;
}

const removeToken = () => {
  token.value = null;
  window.localStorage.removeItem(TOKEN_STORAGE_KEY);
};

const getMe = async () => {
  const {data} = await client.get('me/')
  return data
}
const storeProfile = (data: any) => {
  profile.value = data
  window.localStorage.setItem('profile', JSON.stringify(data))
}
const removeProfile = () => {
  profile.value = null
  window.localStorage.removeItem('profile')
}
const getProfileFromLocalStorage = () => {
  const data = window.localStorage.getItem('profile');
  if (data == null) return null;
  return JSON.parse(data);
}

const getGoogleOauthClientId = async () => {
  const {data} = await client.get('get_google_client_id/')
  return data.client_id;
}
const signInWithGoogle = async (email: string) => {
  const {data} = await client.post('signin_with_google/', { email: email })
  return data;
}

const resetPassword = async (postData: IResetPassword) => {
  const {data} = await client.post('/password-reset/', postData);
  return data;
}

const updatePassword = async (postData: IUpdatePassword, uidb64: string, token: string) => {
  const {data} = await client.post(`/password-reset/confirm/${uidb64}/${token}/`, postData);
  return data;
}

export default function useAuth () {
  return {
    token,
    profile,
    createToken,
    storeToken,
    getTokenFromLocalStorage,  
    removeToken,
    fetchUsers,
    getMe,
    storeProfile,
    removeProfile,
    getProfileFromLocalStorage,
    getGoogleOauthClientId,
    signInWithGoogle,
    resetPassword,
    updatePassword
  }
}