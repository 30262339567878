
import { computed, defineComponent, onMounted, PropType, ref, SetupContext } from 'vue';
export default defineComponent({
  props: {
    modelValue: Boolean
  },
  emits: [
    'update:modelValue'
  ],
  setup(props, context: SetupContext) {
    const onClick = () => {
      context.emit('update:modelValue', !props.modelValue)
    }
    return {
      onClick
    }
  }
});
