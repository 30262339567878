import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/proposals',
    name: 'Proposals',
    component: () => import(/* webpackChunkName: "Proposals" */ '../views/Proposals.vue'),
    children: [
      {
        path: '',
        name: 'ProposalsList',
        component: () => import(/* webpackChunkName: "ProposalsList" */ '../views/Proposals/List.vue')
      },
      {
        path: ':id',
        name: 'ProposalsDetail',
        component: () => import(/* webpackChunkName: "ProposalsDetail" */ '../views/Proposals/Detail.vue'),
        children: [
          {
            path: 'edit',
            name: 'ProposalsDetailEdit',
            component: () => import(/* webpackChunkName: "ProposalsDetailEdit" */ '../views/Proposals/Detail/Edit.vue'),
            
          },
          {
            path: 'approval',
            name: 'ProposalsDetailApproval',
            component: () => import(/* webpackChunkName: "ProposalsDetailApproval" */ '../views/Proposals/Detail/Approval.vue'),
            
          },
          
        ]
      },
      
      {
        path: 'status',
        name: 'ProposalsStatus',
        component: () => import(/* webpackChunkName: "ProposalsStatus" */ '../views/Proposals/Status.vue')
      },
      
    ]
  },
  {
    path: '/purposes',
    name: 'Purpose',
    component: () => import(/* webpackChunkName: "Purposes" */ '../views/Purpose.vue'),
  },
  {
    path: '/relations',
    name: 'OwnerRelation',
    component: () => import(/* webpackChunkName: "OwnerRelations" */ '../views/OwnerRelation.vue'),
  },
  {
    path: '/benefits',
    name: 'Benefit',
    component: () => import(/* webpackChunkName: "Benefits" */ '../views/Benefit.vue'),
  },
  {
    path: '/ownertypes',
    name: 'OwnerType',
    component: () => import(/* webpackChunkName: "OwnerTypes" */ '../views/OwnerType.vue'),
  },
  {
    path: '/password-reset',
    name: 'RequestResetPassword',
    component: () => import(/* webpackChunkName: "RequestResetPassword" */ '../views/ResetPassword.vue')
  },
  {
    path: '/password-reset/done',
    name: 'RequestResetPasswordDone',
    component: () => import(/* webpackChunkName: "RequestResetPasswordDone" */ '../views/ResetPasswordDone.vue')
  },
  {
    path: '/password-reset/confirm/:uidb64/:token',
    name: 'ResetPasswordConfirm',
    component: () => import(/* webpackChunkName: "ResetPasswordConfirm" */ '../views/ResetPasswordConfirm.vue')
  },
  {
    path: '/password-reset/complete',
    name: 'ResetPasswordComplete',
    component: () => import(/* webpackChunkName: "ResetPasswordComplete" */ '../views/ResetPasswordComplete.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/Error/NotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
