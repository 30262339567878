
import { defineComponent, onMounted, PropType, ref, SetupContext } from 'vue';
import {debounce} from 'lodash'

export default defineComponent({
  components: {
        
  },
  props: {
    modelValue: {
      type: String,
    },
    error: {
      type: String
    }
  },
  emits: [
    'update:modelValue'
  ],
  setup(props, ctx: SetupContext) {
    
    // const {
    //   error,
    //   validateElementValueByRules
    // } = useFormField();
    
    const inputElement = ref<any>(null);

    // const validateValue = (value: any) => {
    //   if (inputElement.value == null) return;
    //   error.value = '';
    //   inputElement.value.setCustomValidity('');

    //   const message = validate(value, props.rules);

    //   if (message) {
    //     // inputElement.value.setCustomValidity(message);
    //     inputElement.value.setCustomValidity(message)
         
    //     error.value = message;
    //   }
    //   return message;
    // }
    
    // const debouncedValidation = debounce(() => {validateElementValueByRules(inputElement, props.rules)}, 300);
    const onInput = (e: InputEvent) => {
      // debouncedValidation();
      ctx.emit('update:modelValue', inputElement.value.value);
    };
    
    // onMounted(() => {
    //   if (inputElement.value) {
        
    //     // inputElement.value.setCustomValidity('入力してください')
    //     const message = validateElementValueByRules(inputElement, props.rules);
    //     inputElement.value.onsubmit = (e: any) => {
    //       console.log('on submit')
    //     }
    //   }  
    // });
    
    const onClickLabel = () => {
      if (inputElement.value == null) return;
      inputElement.value.focus();
    }

    return {
      onClickLabel,
      inputElement,
      onInput,
      // error,
    }
  }
})
