
import { defineComponent, onMounted, PropType, ref, SetupContext } from 'vue';

export default defineComponent({
  components: {
        
  },
  props: {
    modelValue: {
      type: String,
    }
  },
  setup(props, ctx: SetupContext) {
    return {
    }
  }
})
