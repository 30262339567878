import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './index.css'

import BaseLayout from '@/layouts/Base.vue';

import TextField from '@/components/TextField.vue';
import FrameModal from '@/components/FrameModal.vue';
import ColorLabel from '@/components/ColorLabel.vue';
import Toggle from '@/components/Toggle.vue';
import SelectBox from '@/components/SelectBox.vue'

const app = createApp(App);
app.use(router);
app.component('BaseLayout', BaseLayout);
app.component('SelectBox', SelectBox);
app.component('TextField', TextField);
app.component('FrameModal', FrameModal);
app.component('ColorLabel', ColorLabel);
app.component('MyToggle', Toggle);
app.mount('#app');
