<template>
  <div class="relative text-left">
    <label
      v-if="$attrs.label"
      :class="{ 'text-red-600': error }"
      :for="$attrs.id" class="left-2 px-1 text-sm ">
      {{ $attrs.label }}
    </label>
    <select
      ref="inputElement"
      :readonly="$attrs.readonly"
      :id="$attrs.id"
      :value="modelValue"
      :autocomplete="$attrs.autocomplete ? $attrs.autocomplete : 'on'"
      @change="onChange"
      :class="{ 'ring-1 ring-red-400': error }"
      class="h-10 w-full px-2 focus:outline-none focus:ring rounded-lg bg-white"
    >
      <option
        class="p-2 text-gray-600"
        :selected="o.value == modelValue"
        v-for="(o, i) in options"
        :key="i"
        :value="o.value"
      >
        {{ o.text }}
      </option>
    </select>
    <div
      v-if="error"
      class="py-1 pl-1 text-xs text-red-600"
    >{{ error }}</div>
  </div>
</template>
<style lang="scss" scoped>

</style>
<script lang="ts">
import { defineComponent, onMounted, PropType, ref, SetupContext } from 'vue';
import {debounce} from 'lodash'

export default defineComponent({
  components: {
        
  },
  props: {
    modelValue: {
      type: String,
    },
    options: {
      type: Array
    },
    error: {
      type: String
    }
  },
  emits: [
    'update:modelValue'
  ],
  setup(props, ctx: SetupContext) {
    
    // const {
    //   error,
    //   validateElementValueByRules
    // } = useFormField();
    
    const inputElement = ref<any>(null);

    // const validateValue = (value: any) => {
    //   if (inputElement.value == null) return;
    //   error.value = '';
    //   inputElement.value.setCustomValidity('');

    //   const message = validate(value, props.rules);

    //   if (message) {
    //     // inputElement.value.setCustomValidity(message);
    //     inputElement.value.setCustomValidity(message)
         
    //     error.value = message;
    //   }
    //   return message;
    // }
    
    // const debouncedValidation = debounce(() => {validateElementValueByRules(inputElement, props.rules)}, 300);
    const onChange = (e: InputEvent) => {
      // debouncedValidation();
      console.log(inputElement.value.value)
      ctx.emit('update:modelValue', inputElement.value.value);
    };
    
    // onMounted(() => {
    //   if (inputElement.value) {
        
    //     // inputElement.value.setCustomValidity('入力してください')
    //     const message = validateElementValueByRules(inputElement, props.rules);
    //     inputElement.value.onsubmit = (e: any) => {
    //       console.log('on submit')
    //     }
    //   }  
    // });
    

    return {
      inputElement,
      onChange,
      // error,
    }
  }
})
</script>