
import { defineComponent, onMounted, ref, SetupContext } from 'vue'
import {useRouter} from 'vue-router'
import useAuth from '@/types/Auth'
import { routerNotCheckAuth } from './utils/index'

export default defineComponent({
  setup(props: any, context: SetupContext) {
    const {
      token,
      getTokenFromLocalStorage,
      profile,
      getProfileFromLocalStorage
    } = useAuth();
    
    const router = useRouter()

    onMounted(() => {
      token.value = getTokenFromLocalStorage()
      profile.value = getProfileFromLocalStorage()
      if (token.value == null || profile.value == null) {
        const routerName = router.currentRoute.value.name
        if (routerName && routerNotCheckAuth.indexOf(routerName) == -1) {
          router.push({ name: 'Login' })
        }
      }
    })

    return {

    }
  }
})
